<template>
  <div class="content page-box">
    <div class="approval-detail"><i @click="goBack"></i>审批记录详情</div>
    <div class="appoval-card">
      <div class="approval-header">
        <span>环节</span>
        <div class="">
          <span>审批人</span>
          <span>意见/事由</span>
          <span>日期</span>
        </div>
      </div>
      <div class="approval-step" v-for="(item, index) in bpmInformation" :key="index">
        <div class="step-name">
          <img
            src="../assets/img/start.png"
            alt=""
          />
          <span>{{ item.approvalLink }}</span>
        </div>
        <div class="step-content-out">
          <div class="step-content-in">
            <div class="step-content-people">
              <div>{{ item.approvalPosition }}</div>
              <div>{{ item.approvalId }}</div>
            </div>
            <div class="step-content-event">
              <span class="color-yellow">{{ item.approvalOpinion }}</span>
            </div>
            <div class="step-content-date">
              <span>{{ item.approvalTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bpmHistory",
  data() {
    return {
      moreInformation: false,
      bpmInformation: [], // 页面数据
    }
  },
  created() {
  },
  methods: {
    goBack() {
      const id = this.$route.query.contractId || ''
      this.$router.push({
        name: this.$route.query.routerPath,
        path: `/agreedetail/${id}`,
        params: {
          id: id
        }
      });
    },
    // 获取数据
    getInformation() {
      const params = this.$route.query
      this.$request.post(this.URL.bpmHistory, params).then(res => {
        if (res.code && res.code === '200') {
          this.bpmInformation = res.data
        }
      })
    },
    // 展示更多评论
    showMore() {
      this.moreInformation = !this.moreInformation
    }
  },
  mounted() {
    this.getInformation()
  }
};
</script>
<style lang="less" scoped>
.page-box {
  .approval-detail{
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    padding-left: 18px;
    box-shadow: 0px 1px 0px #EAEAEA;
    margin-bottom: 1px;
    box-sizing: border-box;
    i{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../assets/img/icon-right.png) no-repeat center center;
      background-size: 100%;
      margin-right: 6px;
      vertical-align: middle;
    }
  }
  .appoval-card {
    padding: 18px;
    width: 100%;
    box-sizing: border-box;
    .approval-header{
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: nowrap;
      margin-bottom: 17px;
      &>span:nth-child(1){
        width: 93px;
      }
      &>div{
        width: 100%;
        &>span:nth-child(1){
          width: 21.59%;
          display: inline-block;
        }
        &>span:nth-child(2){
          width: 65.76%;
          display: inline-block;
        }
      }
    }
    .approval-step{
      &:last-child{
        .step-content-out{
          border-left: none !important;
        }
      }
      .step-name{
        height: 24px;
        width: 93px;
        position: relative;
        &>img{
          position: absolute;
          left: 0;
          top: 0;
        }
        span{
          position: absolute;
          left: 36px;
          top: 6px;
        }
      }
      .step-content-out{
        border-left: 2px solid #EAEAEA;
        margin-left: 11px;
        padding-left: 68px;
        padding-bottom: 12px;
        margin-top: -24px;
        .step-content-in{
          .color-red{
            color: #FA5F87;
          }
          &:first-child{
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
          &:last-child{
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          padding: 8px 12px 14px 12px;
          box-sizing: border-box;
          width: 800px;
          // min-height: 100px;
          background-color: #FAFBFD;
          overflow: hidden;
          color: #6F6F6F;
          .step-content-event{
            .color-yellow{
              color: #997236;
            }
            .color-black{
              color: #323232;
            }
          }
          &>div{
            float: left;
          }
          &>div>div{
            margin-bottom: 3px;
          }
          &>div:nth-child(1){
            width: 21.59%;
          }
          &>div:nth-child(2){
            width: 66.76%;
            min-height: 1px;
            padding-right: 100px;
            box-sizing: border-box;
            &>img{
              float: right;
              cursor: pointer;
            }
            .more-information{
              width: 100%;
              padding-right: 4%;
              box-sizing: border-box;
              margin-top: 6px;
              line-height: 18px;
              &>div{
                margin-bottom: 6px;
              }
              &>div:last-child{
                margin-bottom: 0px;
              }
              .more-information-text{
                width: 100%;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
              }
              .active{
                white-space: normal;
                overflow: visible;
                text-overflow: ellipsis;
              }
            }
            .download-list{
              position: relative;
              color: #997236;
              margin-top: 6px;
              &>img{
                position: absolute;
                right: 0.7%;
                top: 0;
                cursor: pointer;
              }
            }
          }
          &>div:nth-child(3){
            width: 11.65%;
          }
        }
      }
    }
    .titile {
      font-size: 20px;
      left: 30px;
    }
    .position {
      font-weight: 700;
    }
    .commit {
      color: blue;
    }

  }
}
</style>